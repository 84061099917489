<template>
  <b-form-group
    class="input-common"
    :label="label"
  >
    <b-input-group
      class="input-group-merge"
    >
      <b-form-select
        v-model="internalValue"
        :readonly="readonly"
        :options="selectOptions"
        :class="{ 'invalid-control': !internalValue && makeRequired }"
        @change="onInput"
        @keydown.enter="$helpers.focusNext"
      />
      <b-input-group-append
        is-text
        :class="{ 'invalid-control-text': !internalValue && makeRequired }"
      >
        <b-img
          width="20"
          :src="image"
        />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormSelect, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BImg,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: null,
    image: {
      type: String,
      default: null,
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'text',
    },
    options: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    makeRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: null,
    }
  },
  computed: {
    selectOptions() {
      return this.options.map(x => ({
        text: x[this.optionLabel],
        value: x[this.optionValue],
      }))
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internalValue = value
      },
    },
  },
  mounted() {
    this.internalValue = this.value
  },
  methods: {
    onInput($event) {
      this.internalValue = $event
      this.$emit('input', this.internalValue)
      this.$emit('update:modelValue', $event)
      this.$emit('update')
    },
  },
}
</script>
