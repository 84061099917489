<template>
  <b-form-group
    class="input-common"
    :label="label"
  >
    <b-input-group
      class="input-group-merge"
    >
      <b-form-input
        v-model="internalValue"
        v-mask="mask"
        :readonly="readonly"
        :type="type"
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        :class="{ 'invalid-control': !internalValue && makeRequired }"
        @keypress="onInput"
        @keydown="onInput"
        @keyup="onInput"
        @keydown.enter="$helpers.focusNext"
      />
      <b-input-group-append
        v-if="image"
        is-text
        :class="{ 'invalid-control-text': !internalValue && makeRequired }"
      >
        <b-img
          width="20"
          :src="image"
        />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: null,
    image: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    makeRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internalValue = value
      },
    },
    internalValue: {
      immediate: true,
      handler() {
        if (this.type === 'date') {
          this.$emit('input', this.internalValue)
        }
      },
    },
  },
  mounted() {
    this.internalValue = this.value
  },
  methods: {
    onInput($event) {
      this.internalValue = $event.target.value
      this.$emit('input', this.internalValue)
      this.$emit('update:modelValue', $event.target.value)
      this.$emit('update')
    },
  },
}
</script>
